@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

body {
  overflow-x: hidden !important;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  font-family: "Poppins", sans-serif;
  color: #000000;
}
@media only screen and (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
@media screen and (max-width: 767px) {
  body {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }
  body::-webkit-scrollbar {
    display: none !important;
  }
}

a:hover {
  text-decoration: none;
  color: inherit;
}
button:focus {
  outline: none;
}
.th-roboto {
  font-family: "Roboto", sans-serif;
}

/* font color */
.th-black {
  color: #000000;
}

.th-grey {
  color: #6a6a6a;
}

.th-grey-2 {
  color: #f4f4f4;
}

.th-white {
  color: #ffffff;
}

.th-primary {
  color: #ff4b33;
}

.th-yellow {
  color: #ffb500;
}
.th-blue {
  color: #3f4d90;
}
.th-clinic {
  color: #547AF5;
}
.th-text-hint {
  color: #747b8d;
}

/* Link */
.th-link {
  color: #000000;
}

.th-link:hover {
  text-decoration: none;
  /* color: #ff4b33; */
}

.th-link:active {
  text-decoration: none;
  /* color: #ff4b33; */
}

.th-link-1:hover {
  text-decoration: none;
  color: #ffffff;
}

.th-link-black {
  color: #000000;
}

.th-link-black:hover {
  text-decoration: none;
  color: #000000;
}

.th-link-black:active {
  text-decoration: none;
  color: #000000;
}

/* BUTTON */
.th-btn-primary {
  background-color: #ff4b33;
  color: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
}
.th-btn-white {
  background-color: #ffffff;
  color: #000000;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.th-btn-black {
  background-color: #000000;;
  color: #ffffff!important;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.1);
  border-radius: 10px
}
.th-btn-white:focus,
.th-btn-primary:focus,.th-btn-black:focus {
  outline: none;
}

/* BG Color */
.th-bg-white {
  background: #ffffff;
}
.th-bg-primary {
  background: #ff4b33;
}
.th-bg-color-1 {
  background: #d9dded;
}

.th-bg-grey-1 {
  background: #f4f4f4;
}
.th-bg-yellow {
  background: #ffc81a;
}
.th-bg-clinic{
  background: #004cff;
}
.th-bg-appointment{
  background: #059086;
}
.th-bg-blue {
  background: #52509d;
}
/* Input */
.th-input :focus {
  box-shadow: none !important;
  outline: none;
}

/* Gradient */
.th-primary-gradient {
  background: linear-gradient(145.22deg, #ffc033 62.28%, #ff4b33 100%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.th-blue-gradient {
  background: linear-gradient(145.22deg, #49549a 62.28%, #191f44 100%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.th-search-input:focus {
  outline: none;
}

@media screen and (max-width: 767px) {
  .th-hidden-sm {
    display: none !important;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .th-hidden-md {
    display: none !important;
  }
}
@media screen and (min-width: 991px) {
  .th-hidden-lg {
    display: none !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.th-no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.th-no-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

label {
  margin-bottom: 0 !important;
}

.slick-prev:before {
  /* background: #FFFFFF; */
  color: red !important;
  font-size: 30px;
}
.slick-next:before {
  /* background: #FFFFFF; */
  color: red !important;
  font-size: 30px;
}
.slick-prev {
  left: -60px;
}
.slick-next {
  right: -40px;
}
.slick-list {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
}
.th-contact-margin {
  margin-top: -240px;
}
@media screen and (min-width: 768px) {
  .th-contact-margin {
    margin-top: -280px;
  }
}
.th-pb-0 {
  padding-bottom: 0px !important;
}

/* HEADER */
.th-header-underline {
  border-bottom: #ff4b33 3px solid;
}

.th-header-dropbtn {
  background-color: rgba(0, 0, 0, 0);
  /* color: white; */
  /* padding: 16px; */
  /* font-size: 16px; */
  border: none;
  cursor: pointer;
}

.th-header-dropdown {
  position: relative;
  display: inline-block;
}

.th-header-dropdown-content {
  display: none;
  position: absolute;
  top: 33px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.th-header-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.th-header-dropdown-content a:hover {
  background-color: #ddd;
}

.th-header-dropdown:hover .th-header-dropdown-content {
  display: block;
}

/* .th-header-dropdown:hover .th-header-dropbtn {
  color: #ff4b33;
} */

/* Drawer */
.th-side-drawer-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1500;
  top: 0;
  left: 0;
}

.th-side-drawer {
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 65%;
  z-index: 2000;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.th-pb-0 {
  padding-bottom: 0px !important;
}

.th-border-patch-blue {
  border-left: 8px solid #3f4d90;
}
.th-border-patch-yellow {
  border-left: 8px solid #ffc81a;
}
.th-border-patch-clinic {
  border-left: 8px solid #004cff;
}
.th-border-patch-orange {
  border-left: 8px solid #ff4b33;
}
.th-side-drawer.open {
  transform: translateX(0%);
}

.slick-next::before {
  content: "\203A" !important;
  color: #000e !important;
  background: #cccccc;
  padding: 0 16px 12px 16px;
  border-radius: 25px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.slick-prev:before {
  content: "\2039" !important;
  color: #000 !important;
  background: #cccccc;
  padding: 0 16px 12px 16px;
  border-radius: 25px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.th-school-bg-color {
  background-color: #ffffff;
}
@media screen and (max-width: 768px) {
  .th-school-bg-color {
    background-color: #f3f3f3;
  }
}
